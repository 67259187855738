<template>
  <v-container fluid>
    <v-row class="mt-4" align="start">
      <v-col cols="12" md="6">
        <p class="title">
          {{ movementType && movementType === "insert" ? "Inclusão de Grupo Familiar:" : "Implantação:" }}
          Adicionar Titular
        </p>
      </v-col>
      <v-col cols="12" lg="6">
        <v-stepper tile value="1">
          <v-stepper-header>
            <v-stepper-step step="1" color="textPrimary">
              Informações do Beneficiário e do Plano
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4"></v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
          <v-card
            elevation="2"
            class="pa-6"
            rounded="xl">
            <v-form
              ref="formSearch"
              v-model="isValidFormSearch">
              <v-row>
                <v-col cols="12">
                  <p class="title">Informações do Titular</p>
                </v-col>
                <v-col cols="12" md="4"
                  class="pb-0">
                  <label
                    class="label">
                    Grupo Financeiro
                  </label>
                  <v-autocomplete
                    :loading="isLoadingFinancialGroups"
                    :items="financialGroups"
                    :rules="[rule.required]"
                    :disabled="financialGroups.length === 1 || isCriticizedCarrier"
                    @input="loadContractByFinancialGroup(beneficiary.financialGroup, 'field')"
                    validate-on-blur
                    v-model="beneficiary.financialGroup"
                    append-icon="fas fa-chevron-down"
                    placeholder="Informe o Grupo Financeiro"
                    item-color="textPrimary"
                    color="textPrimary"
                    item-text="name"
                    item-value="id"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="4"
                  class="pb-0">
                  <label
                    class="label">
                    CPF
                  </label>
                  <v-text-field
                    placeholder="Informe o CPF"
                    v-model="beneficiary.cpf"
                    v-mask="'###.###.###-##'"
                    :rules="[rule.required, rule.cpf]"
                    validate-on-blur
                    outlined
                    color="textPrimary"
                    :disabled="isDisabledBeneficiaryData"
                    @keyup="onClickSearch('cpf')"
                  />
                </v-col>
                <v-col cols="12" md="4"
                  class="pb-0">
                  <label
                    class="label">
                    Nome Completo
                  </label>
                  <v-text-field
                    ref="beneficiaryName"
                    placeholder="Informe o nome"
                    maxlength="70"
                    v-model.trim="beneficiary.name"
                    outlined
                    color="textPrimary"
                    :disabled="isDisabledBeneficiaryData"
                    :rules="beneficiary.name ? [rule.validateIsAlphanumeric, rule.name] : []"
                    validate-on-blur
                    @blur="beneficiary.name = formatter.formatToTitleCase(beneficiary.name)"
                    @keyup="onClickSearch('name')"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <label class="label">Departamento</label>
                  <v-text-field
                    placeholder="Informe o departamento"
                    maxlength="255"
                    v-model="beneficiary.department"
                    outlined
                    color="textPrimary"
                  />
                </v-col>
                <v-col cols="12" md="4"
                  class="pb-0">
                  <label
                    class="label">
                    Centro de Custo
                  </label>
                  <v-row
                    no-gutters>
                    <v-col
                      cols="3">
                      <v-text-field
                        validate-on-blur
                        v-model="beneficiary.capacityCode"
                        placeholder="Código"
                        color="textPrimary"
                        outlined
                        class="rounded-r-0"
                        @input="['', null, undefined].some(item => item === beneficiary.capacityCode) ? beneficiary.capacityDescription = '' : false"
                      />
                    </v-col>
                    <v-col
                      cols="9">
                      <v-text-field
                        v-model="beneficiary.capacityDescription"
                        class="rounded-l-0"
                        placeholder="Descrição"
                        color="textPrimary"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <label
                    class="label">
                    Data de Admissão
                  </label>
                  <div class="d-flex flex-column">
                    <v-text-field
                      outlined
                      v-model="beneficiary.admissionDate"
                      append-icon="fas fa-calendar-alt"
                      v-mask="'##/##/####'"
                      placeholder="DD/MM/YYYY"
                      color="textPrimary"
                      :rules="[rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid]"
                      validate-on-blur
                      :disabled="isDisabledBeneficiaryData"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="12">
                  <v-alert
                    :value="alert.show"
                    transition="fade-transition"
                    class="py-4"
                    icon="mdi-alert"
                    color="textPrimary"
                    width="100%"
                    dense
                    outlined>
                    {{ alert.text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-card
            elevation="2"
            class="px-3 py-6 my-6"
            rounded="xl">
            <v-row>
              <v-col
                cols="12"
                class="pb-0">
                <p
                  class="title pb-0 mb-0 pl-2">
                  Benefícios
                </p>
              </v-col>
              <v-col
                cols="12">
                <v-data-table
                  :headers="headers"
                  :items="beneficiary.plans"
                  :page="page"
                  class="transparent"
                  hide-default-footer
                  hide-default-header
                  item-key="id">
                  <template
                    v-slot:no-data>
                    <span>
                      Nenhum item foi encontrado.
                    </span>
                  </template>

                  <template
                    v-slot:item="{ item, index }">
                    <div
                      class="px-3">
                      <v-container
                        fluid
                        style="border-bottom: 1px solid #cfd1d2;">
                        <v-row
                          class="d-flex flex-nowrap">
                          <div
                            class="d-flex flex-grow-1 flex-shrink-1 pt-8 pb-2 pr-4">
                            <v-row>
                              <v-col
                                v-bind="columnsParam">
                                <label
                                  class="label">
                                  Contrato
                                </label>
                                <v-autocomplete
                                  :loading="isLoadingContract"
                                  :items="item.contracts"
                                  :rules="item.contract !== null ? [] : [rule.required]"
                                  @input="loadSubcontractByContractId(item, index)"
                                  v-model="item.contract"
                                  validate-on-blur
                                  item-text="text"
                                  item-value="contract"
                                  placeholder="Informe o Contrato"
                                  color="textPrimary"
                                  item-color="textPrimary"
                                  append-icon="fas fa-chevron-down"
                                  outlined
                                />
                              </v-col>
                              <v-col
                                v-bind="columnsParam">
                                <label
                                  class="label">
                                  Subcontrato
                                </label>
                                <v-autocomplete
                                  :rules="item.subcontract !== null ? [] : [rule.required]"
                                  :items="item.subcontracts"
                                  :loading="beneficiaryRowSelected === index || isCriticizedCarrier
                                    ? isLoadingSubContract
                                    : false"
                                  @input="loadEligibilityAndUnitByContractIdAndSubcontractId(item, index)"
                                  v-model="item.subcontract"
                                  item-text="text"
                                  item-value="subcontract"
                                  placeholder="Informe o Subcontrato"
                                  color="textPrimary"
                                  item-color="textPrimary"
                                  append-icon="fas fa-chevron-down"
                                  outlined
                                  validate-on-blur
                                />
                              </v-col>
                              <v-col
                                v-bind="columnsParam">
                                <label
                                  class="label">
                                  Unidade
                                </label>
                                <v-row
                                  no-gutters>
                                  <v-col
                                    cols="3">
                                    <v-autocomplete
                                      class="rounded-r-0"
                                      :items="item.units"
                                      v-model="item.unit"
                                      @change="setUnit(item, $event)"
                                      item-text="code"
                                      item-value="id"
                                      color="textPrimary"
                                      outlined
                                      placeholder="Código"
                                      :rules="item.units.length > 0 ? [rule.required] : []"
                                      append-icon="fas fa-chevron-down"
                                      :disabled="!item.subcontract"
                                      return-object
                                    />
                                  </v-col>
                                  <v-col
                                    cols="9">
                                    <v-text-field
                                      class="rounded-l-0"
                                      :value="item
                                        ? item.unit
                                          ? item.unit.description
                                          : ''
                                        : ''"
                                      color="textPrimary"
                                      outlined
                                      placeholder="Descrição"
                                      disabled
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col
                                v-bind="columnsParam">
                                <label
                                  class="label">
                                  Elegibilidade
                                </label>
                                <v-autocomplete
                                  :rules="item.eligibility !== null ? [] : [rule.required]"
                                  :loading="
                                    beneficiaryRowSelected === index || isCriticizedCarrier
                                    ? isLoadingEligibility
                                    : false"
                                  :items="item.eligibilities"
                                  @input="loadPlansByEligibility(item);"
                                  v-model="item.eligibility"
                                  item-text="elegibility_id.name"
                                  item-color="textPrimary"
                                  item-value="id"
                                  placeholder="Elegibilidade"
                                  color="textPrimary"
                                  append-icon="fas fa-chevron-down"
                                  validate-on-blur
                                  outlined
                                />
                              </v-col>
                              <v-col
                                v-bind="columnsParam">
                                <label
                                  class="label">
                                  Plano
                                </label>
                                <v-autocomplete
                                  :rules="item.plan !== null ? [] : [rule.required]"
                                  :loading="
                                    beneficiaryRowSelected === index || isCriticizedCarrier
                                    ? isLoadingPlan
                                    : false"
                                  :items="item.plans"
                                  v-model="item.plan"
                                  item-text="name"
                                  item-value="id"
                                  placeholder="Plano"
                                  color="textPrimary"
                                  item-color="textPrimary"
                                  append-icon="fas fa-chevron-down"
                                  validate-on-blur
                                  outlined
                                />
                              </v-col>
                              <v-col
                                v-bind="columnsParam">
                                <label
                                  class="label">
                                  Início De Vigência
                                </label>
                                <div
                                  class="d-flex flex-column">
                                  <v-menu
                                    v-model="validityDateMenu[index]"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                    <template
                                      v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :disabled="!item.contract"
                                        :rules="
                                          item.validityDate !== null
                                          ? []
                                          : [
                                              rule.required,
                                              rule.requiredDate,
                                              rule.isAfterSpecificYear,
                                              rule.isDateValid,
                                              rule.endDateIsGreaterThanOrEqualToStartDate(
                                                beneficiary.admissionDate,
                                                item.validityDate,
                                                'Data início de vigência deve ser maior ou igual a data de admissão'
                                              )
                                            ]"
                                        @click="getAllowedDates(item, index)"
                                        v-model="item.validityDate"
                                        :loading="
                                          beneficiaryRowSelected === index || isCriticizedCarrier
                                          ? isLoadingValidityDate
                                          : false"
                                        append-icon="fas fa-calendar-alt"
                                        placeholder="DD/MM/YYYY"
                                        v-mask="'##/##/####'"
                                        color="textPrimary"
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                        clearable
                                        outlined
                                        validate-on-blur
                                      />
                                    </template>
                                    <AllowedDatePicker
                                      @date="updateValidityDate($event, item, index)"
                                      :allowedDates="allowedDates"
                                    />
                                  </v-menu>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                          <div
                            class="d-flex align-center justify-center flex-grow-0 flex-shrink-0 px-4"
                            style="background-color: #F4F6F7;">
                            <v-btn
                              :disabled="beneficiary && beneficiary.plans && beneficiary.plans.length === 1"
                              color="red"
                              small
                              fab
                              elevation="2"
                              @click="onClickDel(item)">
                              <v-icon
                                size="16"
                                color="white">
                                fas fa-trash
                                </v-icon>
                            </v-btn>
                          </div>
                        </v-row>
                      </v-container>
                    </div>
                  </template>
                </v-data-table>
                <v-container
                  fluid
                  class="px-6">
                  <v-row>
                    <v-col
                      cols="12"
                      class="px-0 pt-0">
                      <v-sheet
                        min-height="100"
                        width="100%"
                        color="#F4F6F7"
                        class="d-flex justify-center align-center">
                        <v-btn
                          v-if="!isCriticizedCarrier"
                          style="background-color: #D3D6D9;"
                          height="50"
                          width="50"
                          icon
                          @click="onClickAdd()">
                          <v-icon
                            size="20"
                            color="#F4F6F7">
                            fas fa-plus
                          </v-icon>
                        </v-btn>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
          <div
            v-if="violations && violations.length >0">
            <ViolationRulesAlert  :violations="violations"/>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-footer>
      <v-row class="d-flex pt-6 justify-end">
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          <v-icon
            left
            dark>
            fas fa-chevron-left
          </v-icon>
          Voltar
        </v-btn>
        <v-btn
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          :loading="isLoadingValidation"
          @click="validateGracePeriod()"
        >
          {{ continueWithCriticism ? 'Prosseguir com crítica' : 'Próximo' }}
          <v-icon
            right
            dark>
            fas fa-chevron-right
          </v-icon>
        </v-btn>
      </v-row>
    </v-footer>
    <BeneficiaryDialog
      :show="dialog.show"
      :beneficiaries="beneficiaries"
      @confirm="confirmBeneficiaryDialog"
      @close="closeBeneficiaryDialog"
    />

    <SnackbarCustomize ref="SnackbarCustomize" />

    <div v-if="isCriticizedCarrier">
      <ViewCriticizedViolationDialog />
    </div>

    <v-dialog v-model="validateGraceModal" persistent max-width="800">
      <v-card width="800" class="pa-3 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          <v-row
            class="d-flex justify-space-between px-3 pt-3">
            <span>Incidência de Carência</span>
            <v-icon @click="closeValidateGraceModal()">
              fas fa-times
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="formFilterExportMovement">
            <v-row justify="start">
              <v-col>
                <p>A movimentação desejada excede o prazo máximo dias com relação à Data do Evento. Portanto, incidirá carência.</p>
                <div v-for="grace in gracePeriods">
                  <p v-if="grace.hasGracePeriod">
                    {{grace.benefitId}} - {{grace.policy}} - Acima do prazo máximo de {{grace.daysGracePeriod}} dias
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="px-6 pb-3">
          <v-spacer />
          <v-btn color="primary" width="150px" outlined @click="closeValidateGraceModal()">
            Cancelar
          </v-btn>
          <v-btn color="primary" width="150px" outlined @click="nonConfirmGracePeriod()">
            Atendimento
          </v-btn>
          <v-btn color="primary" width="150px" @click="confirmGracePeriod()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import ContractService from '@/services-http/contract/ContractService';
import ContractUnitService from '@/services-http/contract/ContractUnitService';
import PhysicalPersonService from '@/services-http/contract/PhysicalPersonService';
import Rules from '@/shared/validators/formRules';
import BeneficiaryDialog from '@/components/BeneficiaryDialog/BeneficiaryDialog.vue';
import ContractEligibilityService from '@/services-http/contract/ContractEligibilityService';
import ContractProductService from '@/services-http/contract/ContractProductService';
import lodash from 'lodash';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import MovementRecordFormFieldsToValidate from '@/shared/validators/movementRecordFormFieldsToValidate';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import PayrollService from '@/services-http/sdi/PayrollService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import AllowedDatePicker from '@/components/AllowedDatePicker/AllowedDatePicker.vue';
import CarrierService from '@/services-http/contract/CarrierService';
import moment from 'moment';

export default {
  name: 'BeneficiaryAdditionalInformation',

  components: {
    BeneficiaryDialog,
    ViewCriticizedViolationDialog,
    ViolationRulesAlert,
    SnackbarCustomize,
    AllowedDatePicker,
  },

  data: () => ({
    validityDatePicker: null,
    validityDateMenu: [],
    carrierService: null,
    moment: moment,
    allowedDates: [],
    alert: {
      show: false,
      text: '',
      error: {
        notFound: 'Não foram encontrados resultados para a pesquisa.',
        emptySearch: 'Por favor, preencha um CPF válido ou o nome para pesquisar.',
        isContractSelected: 'Este contrato já foi selecionado. Por favor, selecione um contrato diferente.',
        hasDuplicated: 'Existem contratos duplicados. Por favor, verifique o preenchimento.',
        genericError: 'Ocorreu um erro ao buscar o beneficiário.',
      },
    },
    fab: null,
    beneficiary: {
      cpf: null,
      name: null,
      admissionDate: null,
      department: null,
      capacityDescription: null,
      capacityCode: null,
      plans: [
        {
          contract: null,
          contracts: null,
          subcontract: null,
          subcontracts: null,
          eligibility: null,
          eligibilities: null,
          plan: null,
          plans: null,
          validityDate: null,
          unit: null,
          units: [],
        },
      ],
    },
    dialog: { show: false },
    financialGroups: [],
    fieldsToRulesValidation: [],
    beneficiaryRowSelected: null,
    headers: [
      {
        text: 'Grupo Financeiro',
        value: 'financialGroup',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Subcontrato',
        value: 'subcontract',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Elegibilidade',
        value: 'eligibility',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Início de Vigência',
        value: 'validityDate',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    isValidForm: true,
    isValidFormSearch: true,
    isDisabledBeneficiaryData: false,
    loading: false,
    loadingSearchButton: false,
    movementType: null,
    page: 1,
    beneficiaries: [],
    isRHProtegido: false,
    insuranceCarrierId: null,
    isLoadingFinancialGroups: false,
    isLoadingContract: false,
    isLoadingSubContract: false,
    isLoadingEligibility: false,
    isLoadingPlan: false,
    isCriticizedCarrier: false,
    isLoadingValidation: false,
    isLoadingValidityDate: false,
    hasViolation: false,
    violations: [],
    continueWithCriticism: false,
    componentLoaded: false,
    validateGraceModal: false,
    gracePeriods: [],
    hasAcceptedGracePeriod: null,
    columnsParam: {
      cols: "12",
      xl: "4",
      lg: "4",
      md: "6",
      sm: "12",
      class: "py-1"
    },
    searchTimeout: null,
  }),

  watch: {
    beneficiary: {
      handler(val) {
        if(this.continueWithCriticism){
          this.continueWithCriticism=false;
        }
      },
      deep: true,
      immediate: true,
    },
    'beneficiary.admissionDate': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.beneficiary && this.beneficiary.plans && this.beneficiary.plans.length > 0 && !this.isCriticizedCarrier) {
          this.beneficiary.plans.forEach(plan => { plan.validityDate = '' })
        }
      }
    }
  },

  async mounted() {
    await this.loadFinancialGroups();
    await this.loadDataSessionStorage();
  },

  destroyed() {
    this.componentLoaded = false;
  },

  mixins: [VerifyRoutesMixin, GenericFunctionsMixin],

  methods: {
    setUnit(item, event) {
      item.unitId = event ? event.id : null;
      item.unitCode = event ? event.code : null;
      item.unitDescription = event ? event.description : null;
    },
    blurBeneficiaryName() {
      this.beneficiary.name = formatter.formatToTitleCase(this.beneficiary.name)
    },
    async getAllowedDates(item, index) {
      if (item && !item.contracts || item.contracts && item.contracts.length === 0) {
        this.$refs.SnackbarCustomize.open('error', 'Defina um Grupo Financeiro');
        return;
      }

      const contractIds = Boolean(item.contract)
        ? [item.contract]
        : item.contracts.map((contract) => contract.contract);

      let formatedEventDate = this.beneficiary.admissionDate ? moment(this.beneficiary.admissionDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;

      if (!formatedEventDate) {
        this.$refs.SnackbarCustomize.open('error', 'É necessário informar a Data de Admissão.');
        this.allowedDates = [null];
        return;
      }

      await this.carrierService.GetAllowedDates(contractIds, this.movementType, formatedEventDate).then(response => {
        if (response.status === 200) {
          this.allowedDates = response.data;
        }
      })
    },
    updateValidityDate(value, item, index) {
      if (Boolean(value)) {
        item.validityDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.validityDateMenu.splice(index);
      }
    },
    confirmBeneficiaryDialog(item) {
      this.clearSessionStorage();
      this.dialog.show = false;
      this.beneficiary.name = this.formatter.formatToTitleCase(item.name);
      this.beneficiary.admissionDate = item.admissionDate ? this.formatter.formatDate(item.admissionDate) : item.admission_date ? this.formatter.formatDate(item.admission_date) : null;
      this.beneficiary.cpf = item.cpf ? this.formatter.formatCpf(item.cpf) : item.documentNumber ? this.formatter.formatCpf(item.documentNumber) : null;
      this.$nextTick(() => {
        this.$refs.beneficiaryName.focus();
      });
      sessionStorage.setItem('hasHolder', JSON.stringify(item));
    },
    closeBeneficiaryDialog() {
      this.dialog.show = false;
    },
    closeAlert() {
      setTimeout(() => {
        this.alert.show = false;
        this.alert.text = '';
      }, 5000);
    },
    async loadDataSessionStorage() {
      sessionStorage.removeItem('selectedDate');
      if (sessionStorage.getItem('beneficiaryContractedPlan')) {
        const beneficiaryStore = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));
        this.violations = sessionStorage.getItem('beneficiaryContractedPlanViolations') ? JSON.parse(sessionStorage.getItem('beneficiaryContractedPlanViolations')) : [];

        if (beneficiaryStore && beneficiaryStore.plans && beneficiaryStore.plans.length > 0) {
          beneficiaryStore.admissionDate = this.formatter.formatDate(beneficiaryStore.admissionDate);

          let plans = []

          for (const plan of beneficiaryStore.plans) {
            await this.loadUnitByContractIdAndSubcontractId(plan)
            if (plan.unit && plan.unit.id) {
              await this.getUnitById(plan.unit.id, plan);
            }

            this.isLoadingValidityDate = true;

            plans.push({
              ...plan,
              eligibility: plan.eligibilityInternalId ? plan.eligibilityInternalId : plan.eligibility,
              validityDate: this.formatter.formatDate(plan.validityDate),
            })

            this.isLoadingValidityDate = false;
          };

          beneficiaryStore.plans = plans;

          this.beneficiary = {};
          this.beneficiary = await lodash.cloneDeep(beneficiaryStore);

          this.beneficiary.financialGroup = beneficiaryStore.financialGroup;
          this.beneficiary.department = beneficiaryStore.department;
          this.beneficiary.capacityCode = beneficiaryStore.capacityCode;
          this.beneficiary.capacityDescription = beneficiaryStore.capacityDescription;
          this.beneficiary.cpf = this.formatter.formatCpf(beneficiaryStore.cpf)

          if (sessionStorage.getItem('isCriticizedCarrier')) {
            this.isCriticizedCarrier = true;
            await this.loadContractByFinancialGroup(this.beneficiary.plans[0]);
          }
        }
      }
    },
    onClickAdd() {
      const plan = {
        financialGroup: this.beneficiary.financialGroup,
        contract: null,
        contracts: null,
        subcontract: null,
        subcontracts: null,
        eligibility: null,
        eligibilities: null,
        plan: null,
        plans: null,
        validityDate: null,
        units: [],
        unit: null,
      };

      this.beneficiary.plans.push(plan);

      if (this.beneficiary.plans.length > 1) {
        const contracts = this.beneficiary.plans[0].contracts;
        this.beneficiary.plans[this.beneficiary.plans.length-1].contracts = contracts;
      }
    },
    onClickDel(item) {
      if (this.beneficiary && this.beneficiary.plans && this.beneficiary.plans.length > 0) {
        const elementPosition = this.beneficiary.plans.indexOf(item);
        this.beneficiary.plans.splice(elementPosition, 1);
      }

      if (this.beneficiary && this.beneficiary.plans && !this.beneficiary.plans.length > 0) {
        this.financialGroup = null;
      }
    },
    onClickCancel() {
      this.clearSessionStorage();
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    async validateGracePeriod() {
      if (!this.$refs.formSearch.validate()) {
        !this.$refs.formRegister.validate();
        return
      };

      if (!this.$refs.formRegister.validate()) return;

      this.gracePeriods = [];
      if (this.beneficiary.cpf && this.beneficiary.name && this.beneficiary.admissionDate) {

        if (this.hasDuplicatedContracts()) {
          this.alert.show = true;
          this.alert.text = this.alert.error.hasDuplicated;
          this.closeAlert();
          return;
        }

        let params = [];
        if (this.beneficiary && this.beneficiary.plans && this.beneficiary.plans.length) {
          params = this.beneficiary.plans.map((p) => ({
            parentContractId: p.contract,
            eventDate: this.formatter.formatDateBRtoString(this.beneficiary.admissionDate),
            startDate: this.formatter.formatDateBRtoString(p.validityDate),
          }));

          this.getValidateGracePeriod(params);
        }
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Preencha todas as informações do titular');
      }
    },
    async onClickFinish() {
      if (!this.continueWithCriticism) {
        this.violations = [];
      }

      if (this.beneficiary.cpf && this.beneficiary.name && this.beneficiary.admissionDate) {

        if (this.hasDuplicatedContracts()) {
          this.alert.show = true;
          this.alert.text = this.alert.error.hasDuplicated;
          this.closeAlert();
          return;
        }

        let plans = [];
        if (this.beneficiary && this.beneficiary.plans && this.beneficiary.plans.length) {
          plans = this.beneficiary.plans.map((p) => ({
            index: p.index,
            financialGroup: p.financialGroup,
            contract: p.contract,
            contracts: p.contracts,
            subcontract: p.subcontract,
            subcontracts: p.subcontracts,
            eligibility: p.eligibility,
            eligibilityInternalId: p.eligibility,
            eligibilities: p.eligibilities,
            unit: p.unit,
            unitId: p.unit && p.unit.id ? p.unit.id : null,
            unitCode: p.unit && p.unit.code ? p.unit.code : null ,
            unitDescription: p.unit && p.unit.description ? p.unit.description : null,
            plan: p.plan,
            plans: p.plans,
            validityDate: this.formatter.formatDateBRtoString(p.validityDate),
            hasAcceptedGracePeriod: this.isAcceptedGracePeriod(p.contract),
          }));
        }

        const beneficiaryContractedPlan = {
          cpf: this.formatter.removeNonDigit(this.beneficiary.cpf),
          name: this.beneficiary.name,
          admissionDate: this.formatter.formatDateBRtoString(this.beneficiary.admissionDate),
          financialGroup: this.beneficiary.financialGroup,
          department: this.beneficiary.department,
          costCenterDepartment: this.beneficiary.department,
          capacityCode: this.beneficiary.capacityCode,
          capacityDescription: this.beneficiary.capacityDescription,
          taxAllocationCode: this.beneficiary.capacityCode,
          taxAllocationDescription: this.beneficiary.capacityDescription,
          plans,
        };
        const insurance = beneficiaryContractedPlan.plans[0].subcontracts[0].insuranceCarrierId;
        sessionStorage.setItem('beneficiaryContractedPlan', JSON.stringify(beneficiaryContractedPlan));
        const query = {
          movementType: this.movementType,
          isRHProtegido: this.isRHProtegido,
          insuranceCarrierId: insurance,
          beneficiaryType: 'HOLDER',
        };

        this.isLoadingValidation = true;
        const insertToValidate = { contractedPlan: beneficiaryContractedPlan };
        const fieldsToValidate = this.fieldsToValidate.getFieldsToValidate('beneficiaryPlanInformation');
        const movementType = this.movementType ? this.movementType.toLowerCase() : null;
        const functionValidate = movementType === 'insert' ? this.movementRecordService.ValidateInsert(insertToValidate, fieldsToValidate) : this.movementRecordService.ValidateImplantation(insertToValidate, fieldsToValidate);

        if (!this.continueWithCriticism) {
          functionValidate.then(() => {
            this.isLoadingValidation = false;
            this.redirectRouter('BeneficiaryAdditionalInformation', query);
          }).catch((error) => {
            this.isLoadingValidation = false;
            if (error.response.status === 422) {
              this.violations = [...error.response.data.violations];
              this.continueWithCriticism = true;
              this.$refs.SnackbarCustomize.open('error', 'Erro de validação de regras no preenchimento do formulário');
            }
          });
        } else {
          sessionStorage.setItem('beneficiaryContractedPlanViolations', JSON.stringify(this.violations));
          this.redirectRouter('BeneficiaryAdditionalInformation', query);
        }
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Preencha todas as informações do titular');
      }
    },
    async onClickSearch(searchField) {
      if (searchField === 'cpf') {
        this.beneficiary.name = null;
      }

      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async() => {
        this.$refs.formSearch.validate();

        this.loadingSearchButton = true;
        this.isDisabledBeneficiaryData = true;

        await this.getBeneficiaries();

        this.loadingSearchButton = false;
        this.isDisabledBeneficiaryData = false;
      }, 1500)
    },
    async getBeneficiaries() {
      const cpfRequest = this.beneficiary.cpf ? this.formatter.removeNonDigit(this.beneficiary.cpf) : null;
      const isCpfValid = cpfRequest ? cpfRequest.length === 11 : false;

      if (isCpfValid) {
        await this.getBeneficiaryByPayroll();
      } else if (this.beneficiary.name) {
        await this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPayroll() {
      try {
        const cpfRequest = this.formatter.removeNonDigit(this.beneficiary.cpf);
        const queryString = `?documentNumber=${cpfRequest}`;
        const response = await this.payrollService.FindAllByFilters(queryString);
        if (response.data.content && response.data.content.length > 0) {
          this.beneficiaries = response.data.content;
          this.dialog.show = true;
        } else {
          await this.getBeneficiaryByPhysicalPerson();
        }
      } catch (error) {
        this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPhysicalPerson() {
      try {
        const beneficiaryCpf = this.beneficiary.cpf ? this.beneficiary.cpf.replace(/\D/g, '') : null;
        const beneficiaryName = this.beneficiary.name;
        const payload = {
          isActive: true,
          cpf: beneficiaryCpf,
          name: beneficiaryName,
        };

        const validFilters = Object.entries(payload).filter(([, value]) => value !== null).map(([key, value]) => `${key}=${value}`);
        const queryString = validFilters.length > 0 ? `?${validFilters.join('&')}` : '';
        const response = await this.physicalPersonService.FindAllByFiltersQuery(queryString);

        if (!response.data.empty) {
          this.beneficiaries = await response.data;
          if (this.beneficiaries.length > 0) {
            this.dialog.show = true;
          }
        } else {
          this.showNotFoundAlert();
        }
      } catch (error) {
        this.handleError();
      }
    },
    handleError() {
      this.alert.show = true;
      this.alert.text = this.alert.error.genericError;
      this.closeAlert();
    },
    showNotFoundAlert() {
      this.alert.show = true;
      this.alert.text = this.alert.error.notFound;
      this.clearBeneficiaryData();
      this.closeAlert();
    },
    clearBeneficiaryData() {
      this.beneficiary.cpf = null;
      this.beneficiary.name = null;
      this.beneficiary.admissionDate = null;
    },
    async loadFinancialGroups() {
      this.isLoadingFinancialGroups = true;
      await this.financialGroupService.FindAll().then(async (response) => {
        if (response && response.data) {
          this.financialGroups = response.data;
          if (this.financialGroups && this.financialGroups.length === 1) {
            this.beneficiary.plans[0].financialGroup = this.financialGroups[0].id;
            this.beneficiary.financialGroup = this.financialGroups[0].id;
            await this.loadContractByFinancialGroup(this.beneficiary.plans[0]);
          }
        }
        this.isLoadingFinancialGroups = false;
      }).catch(() => {
        this.isLoadingFinancialGroups = false;
      });
    },
    async loadContractByFinancialGroup(item, type) {
      if (type !== 'field') {
        this.financialGroupDefault = item.financialGroup;
        item.contracts = [];
        item.contract = this.isCriticizedCarrier ? item.contract : null;
        item.subcontracts = [];
        item.subcontract = this.isCriticizedCarrier ? item.subcontract : null;
        item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
        item.eligibilities = [];
        item.plan = this.isCriticizedCarrier ? item.plan : null;
        item.plans = [];
      } else {
        this.financialGroupDefault = this.beneficiary.financialGroup;
      }

      if (this.financialGroupDefault) {
        this.beneficiary.financialGroup = this.financialGroupDefault;
        this.isLoadingContract = true;

        let query = this.mountContractQuery(this.financialGroupDefault);

        await this.contractService.FindAllByFilters(query).then((response) => {
          this.setContractPlans(response.data);

          if (response && response.data && response.data.length) {
            const subContractsFalse = response.data.filter((contracts) => contracts.subcontract === false);
            const contracts = [];
            subContractsFalse.forEach((contract) => {
              let text = '';
              text = (`${(contract.benefit_id && contract.benefit_id.name ? contract.benefit_id.name : contract.benefit_id ? contract.benefit_id : '-')} - ${(contract.carrier_id && contract.carrier_id.xipp_commercial_name ? contract.carrier_id.xipp_commercial_name : '-')} - ${contract.policy ? contract.policy : '-'} - ${contract.contract_owner_id && contract.contract_owner_id.legal_name ? contract.contract_owner_id.legal_name : '-'}`);

              if (contract.carrier_id) {
                contracts.push({
                  accountID: contract.carrier_id.id,
                  contract: contract.id,
                  billing: contract.billing,
                  text,
                });
              }
            });

            this.setContractPlans(contracts);

            if (!this.isCriticizedCarrier) {
              this.cleanSubcontracts();
            }

            this.isLoadingContract = false;

            if (this.isCriticizedCarrier && this.hasSubcontractValidation()) {
              this.loadSubContracts();
            }
          } else {
            this.cleanContracts();

            this.isLoadingContract = false;
          }
        }).catch(() => {
          this.isLoadingContract = false;
        });
      } else {
        this.processBeneficiaryPlanData();
      }
    },
    processBeneficiaryPlanData() {
      this.beneficiary.plans.forEach(item => {
        item.contracts = [];
        item.contract = this.isCriticizedCarrier ? item.contract : null;
        item.subcontracts = [];
        item.subcontract = this.isCriticizedCarrier ? item.subcontract : null;
        item.eligibilities = [];
        item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
        item.plans = [];
        item.plan = this.isCriticizedCarrier ? item.plan : null;
        item.units = [];
        item.unit = this.isCriticizedCarrier ? item.unit : null;
        item.validityDate = this.isCriticizedCarrier ? item.validityDate : null;
      })
    },
    mountContractQuery(financialGroupId) {
      let query = `financialGroupIds=${financialGroupId}&onlyMainFields=true`;

      if (this.isRHProtegido === true) {
        query += `&allowMovementByProtectedHR=${this.isRHProtegido}`;
      }

      return query;
    },
    setContractPlans(contracts) {
      this.processBeneficiaryPlanData();

      this.beneficiary.plans.forEach(item => {
        item.contracts = contracts;
      })
    },
    cleanContracts() {
      this.beneficiary.plans.forEach(item => {
        item.contracts = [];
        item.subcontracts = [];
      })
    },
    cleanSubcontracts() {
      this.beneficiary.plans.forEach(item => {
        item.subcontract = [];
      })
    },
    resetUnitItem(item) {
      item.unit = {
        id: null,
        code: null,
        description: null
      }

      item.unitId = null;
      item.unitCode = null;
      item.unitDescription = null;
    },
    hasSubcontractValidation() {
      return this.beneficiary.plans.some(item => Boolean(item.subcontract))
    },
    loadSubContracts() {
      this.beneficiary.plans.forEach(item => {
        this.loadSubcontractByContractId(item);
      })
    },
    loadSubcontractByContractId(item, index) {
      if (index) {
        this.beneficiaryRowSelected = null
      }
      if (!this.componentLoaded) {
        this.componentLoaded = true
      } else {
        item.validityDate = null;
      }

      this.allowedDates = [null];
      // eslint-disable-next-line no-param-reassign
      item.subcontracts = [];
      // eslint-disable-next-line no-param-reassign
      item.subcontract = this.isCriticizedCarrier ? item.subcontract : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plan = this.isCriticizedCarrier ? item.plan : null;
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      this.resetUnitItem(item);

      if (item.contract !== null) {
        this.isLoadingSubContract = true;
        const query = 'onlyMainFields=true';
        this.contractService.FindByParentIdByFilters(item.contract, query).then((response) => {
          if (response && response.data && response.data.length) {
            const subContractsTrue = response.data.filter((contracts) => contracts.subcontract === true);
            const subcontracts = subContractsTrue.map((subcontract) => {
              const subcontractNumber = subcontract.subcontract_number || '-';
              const contractOwnerLegalName = (subcontract.contract_owner_id && subcontract.contract_owner_id.legal_name) || '-';
              const text = `${subcontractNumber} - ${contractOwnerLegalName}`;

              if (subcontract.carrier_id) {
                this.insuranceCarrierId = subcontract.carrier_id.id;
                return {
                  accountID: subcontract.carrier_id.id,
                  subcontract: subcontract.id,
                  billing: subcontract.billing,
                  insuranceCarrierId: subcontract.carrier_id.id,
                  text,
                };
              }
              return null;
            }).filter((subcontract) => subcontract !== null);

            subcontracts.sort((a, b) => a.text.localeCompare(b.text, { numeric: true }));

            // eslint-disable-next-line no-param-reassign
            item.subcontracts = subcontracts;

            if (this.isCriticizedCarrier && item.subcontract) {
              this.loadEligibilityByContractIdAndSubcontractId(item);
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            item.subcontracts = [];
          }
          this.isLoadingSubContract = false;
        }).catch(() => {
          this.isLoadingSubContract = false;
        });
      }

      this.beneficiaryRowSelected = null;
    },
    async loadEligibilityAndUnitByContractIdAndSubcontractId(item, index) {
      await Promise.all([
        this.loadEligibilityByContractIdAndSubcontractId(item, index),
        this.loadUnitByContractIdAndSubcontractId(item),
      ])
    },
    async loadEligibilityByContractIdAndSubcontractId(item, index) {
      if (index) {
        this.beneficiaryRowSelected = index;
      }
      // eslint-disable-next-line no-param-reassign
      item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plan = this.isCriticizedCarrier ? item.plan : null;
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      item.unit = null;
      item.unitId = null;
      item.unitCode = null;
      item.unitDescription = null;

      if (item.contract !== null && item.subcontract !== null) {
        this.isLoadingEligibility = true;
        const query = `${item.contract}?subcontractId=${item.subcontract}`;
        await this.contractEligibilityService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data) {
            const contractEligibilitiesMap = response.data.map((e) => ({
              key: e.elegibility_id.id,
              value: e.id,
            }));

            const uniqueRecords = response.data.reduce((acc, obj) => {
              const duplicatedIndex = acc.findIndex((it) => it.elegibility_id.id === obj.elegibility_id.id);
              if (duplicatedIndex === -1) {
                acc.push(obj);
              }
              return acc;
            }, []);

            uniqueRecords.forEach((record) => {
              const contractEligibilities = contractEligibilitiesMap.filter(({ key }) => key === record.elegibility_id.id).map(({ value }) => value);
              this.$set(record.elegibility_id, 'contractEligibilities', contractEligibilities);
            });

            item.eligibilities = uniqueRecords;

            item.eligibilities.forEach((eligibility) => {
              const found = eligibility.elegibility_id.contractEligibilities.includes(item.eligibility);
              if (found) {
                this.$set(eligibility, 'id', item.eligibility);
              }
            });
          }

          this.isLoadingEligibility = false;
          if (this.isCriticizedCarrier && item.eligibility) {
            item.eligibilities.forEach((element) => {
              if (element.id === item.eligibility) {
                item.eligibility = element.id;
              }
            });
            this.loadPlansByEligibility(item);
          }
        }).catch(() => {
          this.isLoadingEligibility = false;
        });
      }

      this.beneficiaryRowSelected = null;
    },
    async loadUnitByContractIdAndSubcontractId(item) {
      item.units = []
      await this.contractUnitService.GetUnitByContractIdAndSubcontractId(item.contract, item.subcontract)
      .then((response) => {
        if (response && response.data) {
          item.units = response.data;
        }
      })
    },
    async getUnitById(unitId, plan) {
      await this.contractUnitService.GetUnitById(unitId)
      .then((response) => {
        plan.unit.id = response.data.id;
        plan.unit.code = response.data.code;
        plan.unit.description = response.data.description;
        plan.unit.unitId = response.data.id;
        plan.unit.unitCode = response.data.code;
        plan.unit.unitDescription = response.data.description;
      })
    },
    loadPlansByEligibility(item, index) {
      if (index) {
        this.beneficiaryRowSelected = index;
      }

      item.plan = this.isCriticizedCarrier ? item.plan : null;
      item.plans = [];

      if (item.eligibility !== null) {
        this.isLoadingPlan = true;
        const eligibilityFound = item.eligibilities.find((eligibility) => eligibility.id === item.eligibility);
        let query = '';
        if (eligibilityFound && eligibilityFound.elegibility_id.contractEligibilities) {
          query = `?contractEligibilitiesIds=${eligibilityFound.elegibility_id.contractEligibilities}`;
        } else if (eligibilityFound && eligibilityFound.id) {
          query = `?contractEligibilitiesIds=${eligibilityFound.id}`;
        }

        this.contractProductService.FindAllByFilters(query).then((response) => {
          const res = response.data;
          const plans = [];
          res.forEach((element) => {
            plans.push({
              eligibility_id: element.eligibility_id,
              ...element.plan_id,
              name: `${element.plan_id.name} - ${element.plan_id.code}`,
            });
            // eslint-disable-next-line no-param-reassign
            item.plans = plans;
          });
          this.isLoadingPlan = false;
        }).catch(() => {
          this.isLoadingPlan = false;
        });
      }

      this.beneficiaryRowSelected = null;
    },
    sortPlans(plans, item) {
      if (plans.length) {
        plans.sort((a, b) => (a.text > b.text ? 1 : -1));
      }
      // eslint-disable-next-line no-param-reassign
      item.plans = plans;
    },
    hasDuplicatedContracts() {
      const contractIds = this.beneficiary.plans.map((plan) => plan.contract);
      return contractIds.some(
        (contract, idx) => contractIds.indexOf(contract) !== idx,
      );
    },
    isEmptyFilters() {
      return !(this.beneficiary.cpf || this.beneficiary.name);
    },
    closeValidateGraceModal() {
      this.validateGraceModal = false;
    },
    confirmGracePeriod() {
      this.hasAcceptedGracePeriod = true;
      this.closeValidateGraceModal();
      this.onClickFinish();
    },
    nonConfirmGracePeriod() {
      this.hasAcceptedGracePeriod = false;
      this.closeValidateGraceModal();
      this.onClickFinish();
    },
    getValidateGracePeriod(params) {
      this.contractService.ValidateGracePeriod(params).then((response) => {
        if(response) {
          let hasGracePeriod = false;
          response.data.forEach(element => {
            if(element.hasGracePeriod === true) {
              hasGracePeriod = true;
              this.gracePeriods = response.data;
            }
          });
          if(hasGracePeriod) {
            this.validateGraceModal = true;
          } else {
            this.onClickFinish();
          }
        }
      }).catch(() => {
        this.handleError();
      });
    },
    isAcceptedGracePeriod(contract) {
      let result = null;
      const hasAccepted = this.hasAcceptedGracePeriod;
      this.gracePeriods.some(function(grace) {
          if(grace.parentContractId === contract && grace.benefitId !== null) {
            result = hasAccepted;
          }
      });

      return result;
    }
  },

  async created() {
    this.componentLoaded = false;
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementRecordService = new MovementRecordService();
    this.fieldsToValidate = new MovementRecordFormFieldsToValidate();
    this.physicalPersonService = new PhysicalPersonService();
    this.financialGroupService = new FinancialGroupService();
    this.contractService = new ContractService();
    this.contractUnitService = new ContractUnitService();
    this.contractEligibilityService = new ContractEligibilityService();
    this.contractProductService = new ContractProductService();
    this.payrollService = new PayrollService();
    this.carrierService = new CarrierService();
    this.movementType = this.$route.query.movementType;
    this.loading = true;
  },
};
</script>
